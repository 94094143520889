import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Content, { HTMLContent } from '../components/Content'
import Documentation from '../components/Documentation'

export const DocsPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient font-inter bg_white">
      
    <section className="">
      <div className="">
        <div className="pt-4">

             <Documentation /> 

        </div>
      </div>
    </section>
    </section>
  )
}

DocsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const DocsPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Header overrideDarkTheme={true}/>

      <DocsPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

DocsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DocsPage

export const docsPageQuery = graphql`
  query DocsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
